import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, renderList as _renderList, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3432dbea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "plan-recommended is-uppercase"
}
const _hoisted_2 = { class: "plan-name pb-3" }
const _hoisted_3 = { class: "plan-price" }
const _hoisted_4 = { class: "plan-orders pb-4" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "plan-features"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ld_button = _resolveComponent("ld-button")!
  const _component_i18n_t = _resolveComponent("i18n-t")!
  const _component_tooltip = _resolveComponent("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['plan', { recommended: _ctx.plan.recommended }])
  }, [
    (_ctx.plan.recommended)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.$t('paywall.recommended')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
				'plan-content',
				{
					disabled: _ctx.plan.storeIsOverLimit,
				},
			])
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.plan.name), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", null, [
          _createElementVNode("sup", null, _toDisplayString(_ctx.CURRENCY_SYMBOLS[_ctx.plan.price.currency]), 1),
          _createTextVNode(_toDisplayString(_ctx.plan.price.amount), 1)
        ]),
        _createTextVNode(" /" + _toDisplayString(_ctx.plan.price.recurring) + " ", 1),
        (_ctx.plan.isEnterprise)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createTextVNode(_toDisplayString(_ctx.$t('paywall.andUp')), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _createVNode(_component_ld_button, {
        rounded: "",
        uppercase: "",
        class: "my-4",
        blue: !_ctx.plan.isEnterprise,
        green: _ctx.plan.letsChat,
        grey: _ctx.plan.storeIsOverLimit,
        disabled: _ctx.plan.storeIsOverLimit,
        loading: _ctx.loading,
        onClick: _ctx.choosePlan
      }, {
        default: _withCtx(() => [
          (_ctx.plan.storeIsEnterpriseEnabled && _ctx.plan.letsChat)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.$t('paywall.ctas.pay')), 1)
              ], 64))
            : (_ctx.plan.letsChat)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('paywall.ctas.chat')), 1)
                ], 64))
              : (_ctx.plan.storeIsOverLimit)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('paywall.ctas.overLimit')), 1)
                  ], 64))
                : (_ctx.plan.isActive)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('paywall.ctas.reactivate')), 1)
                    ], 64))
                  : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                      _createTextVNode(_toDisplayString(_ctx.$t('paywall.ctas.start')), 1)
                    ], 64))
        ]),
        _: 1
      }, 8, ["blue", "green", "grey", "disabled", "loading", "onClick"]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_i18n_t, {
          keypath: "paywall.noOfOrders",
          tag: "strong"
        }, {
          orders: _withCtx(() => [
            (_ctx.plan.storeIsOverLimit)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.monthlyOrders), 1))
              : (_ctx.plan.isEnterprise)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('paywall.unlimited')), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('paywall.upTo')) + " " + _toDisplayString(_ctx.plan.transactionLimit), 1)
                  ], 64))
          ]),
          _: 1
        }),
        (_ctx.plan.storeIsOverLimit)
          ? (_openBlock(), _createBlock(_component_tooltip, {
              key: 0,
              arrow: "",
              hover: "",
              placement: "top",
              class: "info-icon-tooltip"
            }, {
              content: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('paywall.infoNote', {
								fromDate: _ctx.from,
								nowDate: _ctx.now,
								limit: _ctx.plan.transactionLimit,
							})), 1)
              ]),
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require('@/assets/info.svg'),
                  alt: "info-icon"
                }, null, 8, _hoisted_6)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      (_ctx.plan.features)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plan.features, (feature, index) => {
              return (_openBlock(), _createElementBlock("p", { key: index }, _toDisplayString(feature), 1))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}