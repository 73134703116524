/* eslint-disable new-cap */
/* eslint-disable no-var */
declare let window: any

/**
 * A class with methods to manage the intercom messenger
 * @see https://developers.intercom.com/installing-intercom/docs/intercom-javascript For documentation details about intercom messenger methods
 */
export class IntercomService {
	private static instance: IntercomService
	private readonly INTERCOM_APP_ID: string
	private readonly INTERCOM_DEFAULT_WIDGTED: string
	private isBooted: boolean

	public static MESSAGE_NAMES = {
		interestInPlusPlan:
			"Hi, I'm interested in learning more about the Plus Plan. Can we set up a call?",
	}

	private constructor() {
		this.INTERCOM_APP_ID = process.env?.VUE_APP_INTERCOM_APP_ID
		this.INTERCOM_DEFAULT_WIDGTED = '#IntercomDefaultWidget'
		this.isBooted = false
	}

	/**
	 * Get the singleton instance of IntercomService
	 * @returns IntercomService
	 */
	public static getInstance(): IntercomService {
		if (!IntercomService.instance) {
			IntercomService.instance = new IntercomService()
		}

		return IntercomService.instance
	}

	/**
	 * Tells the intercom messenger to startup
	 * @param {string} userEmail - the user email
	 * @param {string} userId - the user _id
	 * @param {string} intercomUserIdHash - the intercom user id hash
	 * @param {string} storeName - the sub name in the user profile
	 * @param {boolean} bootInAnyCase - set to true to force the boot even if already booted
	 */
	// eslint-disable-next-line max-params
	public boot(
		userEmail: string,
		userId: string,
		intercomUserIdHash: string,
		storeName: string,
		bootInAnyCase = false
	) {
		if (!this.isBooted || bootInAnyCase) {
			// here we add info to the intercom chat
			window.Intercom('boot', {
				app_id: this.INTERCOM_APP_ID,
				user_email: userEmail,
				user_id: userId,
				user_hash: intercomUserIdHash,
				widget: { activator: this.INTERCOM_DEFAULT_WIDGTED },
				store_name: storeName,
			})

			this.isBooted = true
		}
	}

	/**
	 * shows a message inside intercom messenger chat
	 * @param message the message to be shown
	 */
	public showNewMessageInChat(message: string) {
		window.Intercom('showNewMessage', message)
	}

	/**
	 * shows intercom messenger chat
	 * @param message the message to be shown
	 */
	public show() {
		window.Intercom('show')
	}

	/**
	 * hides the messenger chat if opened but not the button launcher
	 */
	public hide() {
		window.Intercom('hide')
	}

	/**
	 * clear your users’ conversations
	 */
	public shutdown() {
		window.Intercom('shutdown')
	}

	/**
	 * update details on the current user
	 * @param data user details to be updated
	 */
	public update(data: Record<string, unknown>) {
		if (data) {
			window.Intercom('update', data)
		} else {
			window.Intercom('update')
		}
	}

	/**
	 * hides the intercom launcher (the button to open the messenger)
	 */
	public hideLauncher() {
		this.update({
			hide_default_launcher: true,
		})
	}

	/**
	 * shows the intercom launcher (the button to open the messenger)
	 */
	public showLauncher() {
		this.update({
			hide_default_launcher: false,
		})
	}

	/**
	 * it makes possible to call a specific method manually
	 * this is useful for methods that are not implemented in this class but may be needed
	 * @param {string} methodName the intercom method name (e.g. update, hide, show, startTour)
	 * @param {string} methodParam the optional parameter to be passed to the method
	 */
	public callMethodManually(
		methodName: string,
		methodParam?: string | Record<string, unknown> | number | CallableFunction
	) {
		if (methodParam) {
			window.Intercom(methodName, methodParam)
		} else {
			window.Intercom(methodName)
		}
	}
}
