
import { defineComponent, PropType } from 'vue'
import LdButton from '@/components/LdButton/LdButton.vue'
import { PricingPlan } from '../Paywall.vue'
import { format, subDays } from 'date-fns'
import Tooltip from '@/components/Tooltip/Tooltip.vue'
import { sendBehaviorEvent, AppVirtualUrls } from '@/services/segmentTracking'
import { useI18n } from 'vue-i18n'

enum CURRENCY_SYMBOLS {
	usd = '$',
}

export default defineComponent({
	components: {
		LdButton,
		Tooltip,
	},
	props: {
		plan: {
			type: Object as PropType<PricingPlan>,
			default: () => {},
		},
		monthlyOrders: {
			type: String as PropType<string>,
			default: '',
		},
		loading: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
	},
	emits: ['planSelected'],
	setup(props, { emit }) {
		const { t } = useI18n()
		const now = format(new Date(), 'dd MMM')
		const from = format(subDays(new Date(), 90), 'dd MMM')

		const choosePlan = (e: Event) => {
			const target = e.target as HTMLInputElement
			const { name, price } = props.plan

			// emit selected plan details
			emit('planSelected', props.plan)

			sendBehaviorEvent({
				locationTitle: t('paywall.title'),
				virtualUrl: AppVirtualUrls.paywall,
				fieldTitle: `${name} (${price?.amount} ${price?.currency})`,
				actionId: `plan_${name.toLowerCase()}`,
				actionValue: target.innerText,
				actionType: target.tagName,
			})
		}

		return {
			CURRENCY_SYMBOLS,
			now,
			from,
			choosePlan,
		}
	},
})
