
import { defineComponent } from 'vue'
import Popper from 'vue3-popper'

export default defineComponent({
	components: {
		Popper,
	},
	setup() {},
})
