import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a963af1a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "columns is-multiline" }
const _hoisted_3 = { class: "column is-full has-text-centered mt-5 mb-6" }
const _hoisted_4 = { class: "column is-full plans-container" }
const _hoisted_5 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ld_loader = _resolveComponent("ld-loader")!
  const _component_paywall_item = _resolveComponent("paywall-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.$t('paywall.title')), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.userDataLoading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_ld_loader, { type: "bird" })
            ]))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.plans, (plan) => {
              return (_openBlock(), _createBlock(_component_paywall_item, {
                key: plan.id,
                plan: plan,
                "monthly-orders": _ctx.storeMonthlyOrders,
                loading: _ctx.btnLoading,
                onPlanSelected: _ctx.choosePlan
              }, null, 8, ["plan", "monthly-orders", "loading", "onPlanSelected"]))
            }), 128))
      ])
    ])
  ]))
}