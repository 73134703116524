import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createBlock(_component_popper, _mergeProps(_ctx.$attrs, { id: "ld-tooltip" }), {
    content: _withCtx((props) => [
      _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps(props)), undefined, true)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }, 16))
}