
import { defineComponent, computed, watch, ref } from 'vue'
import { useStore } from 'vuex'
import PaywallItem from './components/PaywallItem.vue'
import millify from 'millify'
import { useI18n } from 'vue-i18n'
import { initiateCheckout, updatePayment } from '@/api/payments'
import { ProductPrice, Product, MyPayment, PaymentStatus } from '@/types/payment'
import router from '@/router'
import { RouteNames } from '@/types/routes'
import { sendPageViewEvent, AppVirtualUrls } from '@/services/segmentTracking'
import { IntercomService } from '@/services/intercom'

enum RECURRENCY {
	MONTH = 'month',
	YEAR = 'year',
}

export type PricingPlan = {
	recommended: boolean
	storeIsOverLimit: boolean
	transactionLimit: number | string
	price: ProductPrice | undefined
	storeIsEnterpriseEnabled: boolean
	isEnterprise: boolean
	isActive: boolean
} & Pick<Product, 'id' | 'name' | 'features' | 'letsChat'>

const MILLIFY_OPTIONS = {
	precision: 2,
}
const convertTransactions = (transactions: string): number | null => {
	const number = Number(transactions)

	return !isNaN(number) ? number : null
}
/* eslint-disable max-params */
const mapPricingPlans = (
	plans: Product[],
	storeMonthlyOrders: number,
	storeIsEnterpriseEnabled: boolean,
	recurrency: string,
	activePayment: MyPayment
	// eslint-disable-next-line
) => {
	const { t } = useI18n()
	const { product, active, status } = activePayment
	// find recommended plan
	const recommendedPlan = plans?.find((plan: Product) => {
		const transactions = convertTransactions(plan.transactionLimit)

		return transactions ? storeMonthlyOrders <= transactions : true
	})?.id

	return plans?.map((plan: Product): PricingPlan => {
		const transactions = convertTransactions(plan.transactionLimit)
		const storeIsOverLimit = transactions ? storeMonthlyOrders > transactions : false

		return {
			id: plan.id,
			name: plan.name,
			transactionLimit: transactions
				? millify(transactions, MILLIFY_OPTIONS)
				: t('paywall.unlimited'),
			features: plan.features,
			price: plan.prices.find((price: ProductPrice) => price.recurring === recurrency),
			recommended: recommendedPlan === plan.id,
			storeIsOverLimit,
			storeIsEnterpriseEnabled,
			isEnterprise: transactions === null,
			isActive: plan.id === product?.id && active && status === PaymentStatus.CANCELLED,
			letsChat: plan.letsChat,
		}
	})
}

export default defineComponent({
	components: {
		PaywallItem,
	},
	setup() {
		const store = useStore()
		const recurrency = ref(RECURRENCY.MONTH)
		const products = ref([])
		const btnLoading = ref<boolean>()

		store.dispatch('payments/getStripeProducts')

		const userData = computed(() => store.getters['user/me/getState'])
		const stripePlans = computed(() => store.getters['payments/getState'])
		const paymentData = computed(() => store.getters['payments/payment/getState'])
		const storeMonthlyOrders = ref(userData.value.data.store.monthlyOrders)
		const storeIsEnterpriseEnabled = ref(userData.value.data.store.enterprise)

		watch(stripePlans.value, (currentValue) => {
			products.value = currentValue.data
		})

		const plans = computed(() => {
			return mapPricingPlans(
				products.value,
				storeMonthlyOrders.value,
				storeIsEnterpriseEnabled.value,
				recurrency.value,
				paymentData.value?.data ?? {}
			)
		})
		const choosePlan = async (plan: PricingPlan) => {
			if (plan.letsChat && !plan.storeIsEnterpriseEnabled) {
				// open the intercom messenger with a message
				const msg = IntercomService.MESSAGE_NAMES.interestInPlusPlan
				IntercomService.getInstance().showNewMessageInChat(msg)
			} else if (plan.isActive) {
				btnLoading.value = true

				const updateRequest = await updatePayment()

				btnLoading.value = false

				if (updateRequest) {
					// fetch again the payment after updateing the payment document
					store.dispatch('payments/payment/myPayment')
					router.push({ name: RouteNames.CONNECTION })
				}
			} else {
				try {
					btnLoading.value = true

					const priceId = plan?.price?.priceId
					const { sessionUrl } = await initiateCheckout(priceId as string)

					// if we have a sessionUrl, redirect to Stripe and process payment
					if (sessionUrl && window?.top) window.top.location.href = sessionUrl
				} catch (error) {
					btnLoading.value = false
				}
			}
		}

		return {
			plans,
			userEmail: userData.value.data?.user?.email,
			userId: userData.value.data?.user?._id,
			intercomUserIdHash: userData.value.data?.user?.intercomUserIdHash,
			storeName: userData.value.data?.store?.name,
			storeMonthlyOrders: millify(storeMonthlyOrders.value, MILLIFY_OPTIONS),
			userDataLoading: computed(() => store.getters['payments/isLoading']),
			choosePlan,
			btnLoading,
		}
	},
	watch: {
		$route() {
			// when the page is not anymore the paywall we hide the intercom launcher
			const intercomService = IntercomService.getInstance()
			intercomService.hideLauncher()
		},
	},
	mounted() {
		const { t } = useI18n()

		sendPageViewEvent({
			locationTitle: t('paywall.title'),
			virtualUrl: AppVirtualUrls.paywall,
		})

		if (this.userEmail && this.storeName) {
			const bootInAnyCase = true
			const intercomService = IntercomService.getInstance()
			intercomService.boot(
				this.userEmail,
				this.userId,
				this.intercomUserIdHash,
				this.storeName,
				bootInAnyCase
			)
			intercomService.showLauncher()
		}
	},
})
